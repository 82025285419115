/**
 * Calcula o potencial de doação do usuário
 * O cálculo é feito levando em consideração o valor devido do imposto de renda anual do doador
 * calculado a porcentagem possível de ser destinado a um projeto incentivado 7% para esporte e 6% para os demais
 * 
 * @param {Number} percentage - Porcentagem do imposto de renda que pode ser destinado a um projeto incentivado
 * @param {Number} salary - Salário bruto mensal do doador
 * @param {Number} rendalIncoming - Renda proveniente de aluguéis
 * @param {Number} bonus - Bônus anual do doador
 * @param {Number} dependents - Número de dependentes do doador
 * @param {Number} billsWithEducation - Despesas mensais com Instrução/Educação
 * @param {Number} billsWithHealth - Despesas mensais com Saúde
 * @param {Number} otherDeductions - Outras deduções mensais
 * @param {Number} previdencePrivacy - Previdência privada
 * @returns {Number} - Potencial de doação do usuário
 */
const calculateDonationPotential = (percentage, salary, rentalIncoming, bonus, dependents, billsWithEducation, billsWithHealth, otherDeductions, previdencePrivacy) => {
  const annualIncomes = (salary * 12)
                      + (rentalIncoming * 12) 
                      + bonus;

  const annualDeductions = calculateDependentDeduction(dependents)
                        + calculateMaxDedutionWithEducation(billsWithEducation * 12, dependents)
                        + (billsWithHealth * 12)
                        + (otherDeductions * 12)
                        + calculateMaxDeductionWithPrevidencePrivacy(previdencePrivacy * 12, annualIncomes);

  const inss = calculateInss(salary) * 12;

  const baseCalc = annualIncomes - annualDeductions - inss;

  const irpf = calculateIrpf(baseCalc);
  
  const suggestedDonation = irpf * percentage / 100;//Math.round(irpf * percentage / 100, 2);
  const type = calculateTypeOfIrpf(annualIncomes, annualDeductions, inss);
  const insent = annualIncomes < 30639.90;
  return {
    suggestedDonation,
    type,
    insent,
    annualIncomes,
    annualDeductions,
    inss,
    irpf,
    baseCalc
  };
}

/**
 * Calcula a dedução máxima possível para os gastos com educação
 */
const calculateMaxDedutionWithEducation = (billsWithEducation, dependents) => {
  const maxDeduction = 3561.5;
  const maxDeductionWithDependents = maxDeduction * (parseInt(dependents) + 1);
  return billsWithEducation > maxDeductionWithDependents ? maxDeductionWithDependents : billsWithEducation;
}

/**
 * Calcula a dedução máxima possível para a previdência privada
 */
const calculateMaxDeductionWithPrevidencePrivacy = (previdencePrivacy, annualIncomes) => {
  const maxDeduction = annualIncomes * 12 / 100
  return previdencePrivacy > maxDeduction ? maxDeduction : previdencePrivacy;
}

/**
 * Cálculo de abatimento por dependente
 */
const calculateDependentDeduction = (dependents) => {
  return dependents * 2275.08;
}

/**
 * Cálculo do INSS progressivo
 */
const calculateInss = (salary) => {
  let inss = 0;
  const ranges = [
    { min: 0, max: 1412, sum: 1412 * 7.5 / 100, tax: 7.5},
    { min: 1412.01, max: 2666.68, sum: (2666.68 - 1412.01) * 9 / 100, tax: 9},
    { min: 2666.69, max: 4000.03, sum: (4000.03 - 2666.69) * 12 / 100, tax: 12},
    { min: 4000.04, max: 7786.02, sum: (7786.02 - 4000.04) * 14 / 100, tax: 14}
  ];

  for (let i = 0; i < ranges.length; i++) {
    const range = ranges[i];
    if (salary >= range.min && salary <= range.max) {
      inss =  ((salary - range.min) * range.tax / 100) + ranges.slice(0, i).reduce((acc, range) => acc + range.sum, 0);
    } else if (i === ranges.length - 1) {
      inss =  ranges.reduce((acc, range) => acc + range.sum, 0);
    }    
  }

  return inss.toFixed(2);
}

/**
 * Cálculo do imposto de renda devido no ano
 */
const calculateIrpf = (baseCalc) => {  
  let tax = 0;
  let taxes = [];
  const range1 = { limit: 2529.20 * 12, sum: 0};
  const range2 = { limit: 2826.65 * 12, sum: (2826.65 * 12) - (2259.20 * 12) * 0.075};
  const range3 = { limit: 3751.05 * 12, sum: (3751.05 * 12) - (2826.65 * 12) * 0.15};
  const range4 = { limit: 4664.68 * 12, sum: (4664.68 * 12) - (3751.05 * 12) * 0.225};

  if (baseCalc <= range1.limit) {
      taxes = [0]
  } else if (baseCalc <= range2.limit) {
      taxes = [(baseCalc - range1.limit) * 0.075];
  } else if (baseCalc <= range3.limit) {
      taxes = [
        (baseCalc - range2.limit) * 0.15,
        (range2.limit - range1.limit) * 0.075
      ];
  } else if (baseCalc <= range4.limit) {
      taxes = [
        (baseCalc - range3.limit) * 0.225,
        (range3.limit - range2.limit) * 0.15,
        (range2.limit - range1.limit) * 0.075
      ];
  } else {
      taxes = [
          (baseCalc - range4.limit) * 0.275,
          (range4.limit - range3.limit) * 0.225,
          (range3.limit - range2.limit) * 0.15,
          (range2.limit - range1.limit) * 0.075
      ]
      tax = taxes.reduce((acc, tax) => acc + tax, 0);
  }

  if (tax < 0) tax = 0;

  return tax;
}

function calcularIR(baseCalculo) {
  let irDevido = 0;

  if (baseCalculo <= 2259.20) {
      // Faixa 1: Até R$ 2.259,20 - Isento
      irDevido = 0;
  } else if (baseCalculo <= 2826.65) {
      // Faixa 2: De R$ 2.259,21 até R$ 2.826,65 - 7,5%
      irDevido = (baseCalculo - 2259.20) * 0.075;
  } else if (baseCalculo <= 3751.05) {
      // Faixa 3: De R$ 2.826,66 até R$ 3.751,05 - 15%
      irDevido = (baseCalculo - 2826.65) * 0.15 + (2826.65 - 2259.20) * 0.075;
  } else if (baseCalculo <= 4664.68) {
      // Faixa 4: De R$ 3.751,06 até R$ 4.664,68 - 22,5%
      irDevido = (baseCalculo - 3751.05) * 0.225 + (3751.05 - 2826.65) * 0.15 + (2826.65 - 2259.20) * 0.075;
  } else {
      // Faixa 5: Acima de R$ 4.664,68 - 27,5%
      irDevido = (baseCalculo - 4664.68) * 0.275 + (4664.68 - 3751.05) * 0.225 + (3751.05 - 2826.65) * 0.15 + (2826.65 - 2259.20) * 0.075;
  }

  return irDevido;
}



/**
 * Calcula a melhor opção Declaração Completa ou Simplificada
 */
const calculateTypeOfIrpf = (annualIncomes, annualDeductions, inss) => {
  const maxDiscount = 16754.34;
  let discount = Math.min(annualIncomes * 0.2, maxDiscount);

  if (annualIncomes > 30639.9 && discount < annualDeductions + inss) {
    return "completa";
  }

  return discount < annualDeductions ? "completa" : "simples";
}

/**
 * Test calculateDonationPotential
 */
// const testCalculateDonationPotential = () => {
//   const percentage = 6;
//   const salary = 10000;
//   const rentalIncoming = 0;
//   const bonus = 0;
//   const dependents = 0;
//   const billsWithEducation = 200;
//   const billsWithHealth = 0;
//   const otherDeductions = 0;
//   const previdencePrivacy = 0;
//   return calculateDonationPotential(percentage, salary, rentalIncoming, bonus, dependents, billsWithEducation, billsWithHealth, otherDeductions, previdencePrivacy);
// }

// console.log(testCalculateDonationPotential());

export { calculateDonationPotential }
