import BaseCrudService from "./baseCrud.service";

export default class MemberGetMemberService extends BaseCrudService {

	constructor() {
		super('MemberGetMember');
	}

	getMemberGetMember(cpf) {
		return this.getRequest('GetMemberGetMember', {cpf: cpf});
	}

  createMemberGetMember(user) {
		return this.postRequest('CreateMemberGetMember', { name: user.name, cpf: user.cpf, phone: user.phone, email: user.email });
	}
}