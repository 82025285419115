export default class Simulator {
	constructor()
	{
		this.childrenQuantity = 0;
		this.monthlySalary = 0;
		this.monthlyProceeds = 0;
		this.annualBonus = 0;
		this.pension = 0;
		this.monthlyEducationExpense = 0;
		this.monthlyMedicalExpense = 0;
		this.otherDeductions = 0;
		this.privatePension = 0;
		this.alimony = 0;
	}
}